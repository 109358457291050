import "./footer.scss";
import { useState } from "react";
import Modal from "../../components/Modal/Modal";

// Используемые картинки
import logoImg from "../../asset/logo.png";
import telegram from "../../asset/socialImg/telegram.svg";
import vkontakte from "../../asset/socialImg/vkontakte.svg";
import gmail from "../../asset/socialImg/gmail.svg";



function Footer() {
    const [modalActive, setModalActive] = useState(false);
    return (
      <footer className="footer">
        <Modal active={modalActive} setActive={setModalActive}></Modal>
        <div className="footer__top">
            <div className="footer__top__logo"><img src={logoImg} alt="" /></div>
            <div className="footer__top__schedule"><h3>Время работы: 08:00 - 20:00 пн-пт</h3><a className="footer__button button__action" onClick={()=>setModalActive(true)}>Вызвать мастера</a></div>
            <div className="footer__top__address">
                <a href="mailto:99-27-72@mail.com" target="_blank">99-27-72@mail.ru</a>
                    <a href="tel:+78422992772">+7(9510) 99-27-72</a>
                <a href="https://yandex.ru/maps/-/CDUCzXlb" target="_blank">432044 г.Ульяновск ул.Винновская,9</a>
            </div>
        </div>
        <div className="footer__bottom">
            <div className="footer__bottom__info">
                <h4>Центр Аква Техник</h4>
                <h5>2014 - 2025 © Все права защищены</h5>
                <h6>Cайт носит информационный характер и не является публичной офертой</h6>
            </div>
            <div className="footer__bottom__social">
                <a href="https://t.me/poverka_ulbot"target="_blank"><img src={telegram} alt="" /></a>
                <a href="https://vk.com/club103351318"><img src={vkontakte} alt="" /></a>
                <a href="mailto:99-27-72@mail.ru"target="_blank"><img src={gmail} alt="" /></a>
            </div>
        </div>
      </footer>
    );
  }
  
  export default Footer;